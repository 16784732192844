.component {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #0000000f, 0px 1px 3px #0000001a;
  height: 1186px;
  position: relative;
  width: 612px;
}

.component .text-wrapper-4 {
  color: #000000;
  font-family: var(--text-sm-lineheight-5-font-normal-font-family);
  font-size: var(--text-sm-lineheight-5-font-normal-font-size);
  font-style: var(--text-sm-lineheight-5-font-normal-font-style);
  font-weight: var(--text-sm-lineheight-5-font-normal-font-weight);
  left: 83px;
  letter-spacing: var(--text-sm-lineheight-5-font-normal-letter-spacing);
  line-height: var(--text-sm-lineheight-5-font-normal-line-height);
  position: absolute;
  top: 127px;
  white-space: nowrap;
}

.component .textarea-instance {
  height: 110px !important;
  left: 80px !important;
  position: absolute !important;
  top: 253px !important;
  width: 447px !important;
}

.component .textarea-2 {
  top: 22px !important;
  width: 423px !important;
}

.component .textarea-3 {
  height: 11px !important;
  left: 433px !important;
  top: 96px !important;
  width: 11px !important;
}

.component .select-instance {
  left: 80px !important;
  position: absolute !important;
  top: 59px !important;
}

.component .text-wrapper-5 {
  color: #000000;
  font-family: var(--text-sm-lineheight-5-font-normal-font-family);
  font-size: var(--text-sm-lineheight-5-font-normal-font-size);
  font-style: var(--text-sm-lineheight-5-font-normal-font-style);
  font-weight: var(--text-sm-lineheight-5-font-normal-font-weight);
  left: 83px;
  letter-spacing: var(--text-sm-lineheight-5-font-normal-letter-spacing);
  line-height: var(--text-sm-lineheight-5-font-normal-line-height);
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.component .tabs-instance {
  display: flex !important;
  height: 31px !important;
  left: 80px !important;
  position: absolute !important;
  top: 168px !important;
  width: 447px !important;
}

.component .text-wrapper-6 {
  color: #000000;
  font-family: var(--text-sm-lineheight-5-font-normal-font-family);
  font-size: var(--text-sm-lineheight-5-font-normal-font-size);
  font-style: var(--text-sm-lineheight-5-font-normal-font-style);
  font-weight: var(--text-sm-lineheight-5-font-normal-font-weight);
  left: 83px;
  letter-spacing: var(--text-sm-lineheight-5-font-normal-letter-spacing);
  line-height: var(--text-sm-lineheight-5-font-normal-line-height);
  position: absolute;
  top: 222px;
  white-space: nowrap;
}

.component .text-wrapper-7 {
  color: #000000;
  font-family: var(--text-sm-lineheight-5-font-normal-font-family);
  font-size: var(--text-sm-lineheight-5-font-normal-font-size);
  font-style: var(--text-sm-lineheight-5-font-normal-font-style);
  font-weight: var(--text-sm-lineheight-5-font-normal-font-weight);
  left: 83px;
  letter-spacing: var(--text-sm-lineheight-5-font-normal-letter-spacing);
  line-height: var(--text-sm-lineheight-5-font-normal-line-height);
  position: absolute;
  top: 422px;
  white-space: nowrap;
}

.component .button-instance {
  display: flex !important;
  height: 49px !important;
  left: 83px !important;
  position: absolute !important;
  top: 635px !important;
  width: 141px !important;
}

.component .text-wrapper-8 {
  color: #000000;
  font-family: var(--text-2xl-lineheight-8-font-medium-font-family);
  font-size: var(--text-2xl-lineheight-8-font-medium-font-size);
  font-style: var(--text-2xl-lineheight-8-font-medium-font-style);
  font-weight: var(--text-2xl-lineheight-8-font-medium-font-weight);
  height: 62px;
  left: 290px;
  letter-spacing: var(--text-2xl-lineheight-8-font-medium-letter-spacing);
  line-height: var(--text-2xl-lineheight-8-font-medium-line-height);
  position: absolute;
  top: 628px;
  width: 249px;
}

.component .overlap-group {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #0000000f, 0px 1px 3px #0000001a;
  height: 422px;
  left: 45px;
  position: absolute;
  top: 718px;
  width: 524px;
}

.component .slider-instance {
  height: 28px !important;
  left: 86px !important;
  position: absolute !important;
  top: 65px !important;
  width: 352px !important;
}

.component .slider-2 {
  width: 175px !important;
}

.component .slider-3 {
  left: 168px !important;
  top: 7px !important;
}

.component .element-requests-per-day {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 48px;
  left: 187px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 150px;
}

.component .span {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.component .text-wrapper-9 {
  font-size: 12px;
}

.component .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 257px;
  left: 19px;
  padding: 10px;
  position: absolute;
  top: 97px;
  width: 486px;
}

.component .table-instance {
  height: 237px !important;
  width: 466px !important;
}

.component .table-2 {
  width: 63px !important;
}

.component .x-words-y-tokens {
  color: #6c6363;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  left: 477px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 369px;
}

.component .textarea-4 {
  height: 110px !important;
  left: 80px !important;
  position: absolute !important;
  top: 453px !important;
  width: 447px !important;
}

.component .x-words-y-tokens-2 {
  color: #6c6363;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  left: 477px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 569px;
}


/* Custom Styling */
*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.wrapper {
	overflow: hidden;
	position: absolute;
  pointer-events: none;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
}
.box {
	position: absolute;
	width: 100%;
	overflow: hidden;
	height: 100%;
	background: linear-gradient(to bottom, #ee0979, #ff6a00);
	z-index: -1;
  top: -100px;
}
.single-box {
	position: absolute;
	left: 0;
	width: 200%;
	height: 100%;
	background-repeat: repeat no-repeat;
	background-position: 0 bottom;
	transform-origin: center bottom;
	background-size: 30% 80px;
	animation: animate 18s linear infinite;
  z-index:-1;
}
@keyframes animate {
	0% {
		transform: translateX(0) translateZ(0) scaleY(1);
	}
	50% {
		transform: translateX(-25%) translateZ(0) scaleY(0.55)
	}
	100% {
		transform: translateX(-50%) translateZ(0) scaleY(1)
	}
}
